<template>
    <div style="padding:.1rem .2rem;text-align: center">
        <van-notice-bar
                :scrollable="false"
                text="只展示最新的10条记录"
        />
        <div v-if="exchangeList.length!==0" class="gift-list ko">
            <div v-for="(item,index) in exchangeList" :key="index" class="single-gift">
                <img :src="item.cover[0]" style="width: 100%;height:3rem;border-top-left-radius:.1rem;border-top-right-radius:.1rem"/>
                <h4>
                    {{item.name}}
                </h4>
                <p>
                    {{item.integral}}
                </p>
                <div class="single-gift-tip" :style="{color:STATUS[item.status]['color']}">
                    <div>
                        {{STATUS[item.status]['text']}}
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <van-empty description="暂无兑换记录"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ExchangeRecord",
        data() {
            return {
                exchangeList: [],

                //1已申请，2，已完成，4取消
                STATUS: {
                    1: {
                        text:'已申请',
                        color:'#d7d823'
                    },
                    2: {
                        text:'已完成',
                        color:'#56d882'
                    },
                    4: {
                        text:'已取消',
                        color:'gray'
                    }
                }
            }
        },
        created() {
            document.title = '兑换记录';
            this.getExchangeRecord()
        },
        methods: {
            getExchangeRecord() {
                this.$axios.get('miniprogram/exchangeList').then((res) => {
                    if (res.data.code === '0000') {
                        this.exchangeList = res.data.content.data
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .gift-list {
        flex-wrap: wrap;
    }

    .single-gift {
        width: 3.4rem;
        height: 4rem;
        border-radius: .1rem;
        margin-top: .3rem;
        box-shadow: 3px 3px 10px #d8d8d8;
        position: relative;
    }

    .single-gift-tip {
        position: absolute;
        top: .2rem;
        transform: rotate(45deg);
        right: -.2rem;
        font-size: .25rem;
        background: black;
        width: 1.2rem;
        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;
    }

    .ko {
        font-size: .3rem;
        display: flex;
        justify-content: space-between;
    }
</style>
